<template>
  <div>
    <div class="search-wrapper panel-heading col-sm-12">
      <b-form-group>
        <label for="search">Arama</label>
        <b-form-input
          id="search"
          v-model="searchTerm"
          placeholder="Firma adı ve pozisyon faktörü ile aranır"
          type="text"
        />
      </b-form-group>
    </div>
    <div
      v-if="deger"
      class="d-flex justify-content-center mt-4 mb-1"
    >
      <h3 class="mr-2">
        Eşleştirilecek talepler yükleniyor, Lütfen bekleyiniz..
      </h3>
      <b-spinner
        variant="success"
      />
    </div>
    <div
      v-for="(r , i) in filteredFirm"
      :key="i"
      class="row mb-2 border row align-items-center "
    >
      <div class="col-lg-2 col-md-2 d-grid text-center">
        <center>
          <img
            :src="r.firmInfo.firmLogo === '' ? require('@/assets/images/firmnoImage.png') : r.firmInfo.firmLogo"
            class="img-fluid"
            width="50"
            heigt="50"
          >
          <br><br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="primary"
            @click="onGetFirmClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Firma Envanter</span>
          </b-button>
        </center>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Firma Unvanı:
              </th>
              <td class="text-center">
                {{ r.firmInfo.firmTitle }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Pozisyon İsmi:
              </th>
              <td class="text-center">
                {{ r.position }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Personel Sınıfı:
              </th>
              <td class="text-center">
                {{ r.staffClass }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Alınacak Kişi Sayısı:
              </th>
              <td class="text-center">
                {{ r.personelCount }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Talep Tarihi:
              </th>
              <td class="text-center">
                {{ r.requestOpenDate.substring(0,10) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Faktör:
              </th>
              <td class="text-center">
                {{ r.factorPosition.staffPositionName }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Çalışma Şekli:
              </th>
              <td class="text-center">
                {{ r.jobType }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Görev Açıklaması:
              </th>
              <td class="text-center">
                {{ r.shortPositionDescription }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-2 col-md-12 inline-blocks text-center">
        <center>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="success"
            @click="onEvaluateBtnClicked(r)"
          >
            <feather-icon
              icon="LinkIcon"
              class="mr-30"
            />
            <b-spinner
              v-if="loading && dugme===r.staffRequestId"
              class="mr-1 ml-1"
              variant="warning"
            />
            <span v-if="!loading || dugme!==r.staffRequestId"> Eşleştirme</span>
            <span v-if="loading && dugme===r.staffRequestId"> Eşleştiriliyor..</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="danger"
            @click="onDuzenleBtnClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Talep Detay</span>
          </b-button>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, VBModal, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiTalep from '@/api/islemler/firmRequest'
import * as apiEval from '@/api/islemler/Evaluation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      newFirmTypeId: -1,
      newFirmTypeName: '',
      updFirmTypeName: '',
      pageLength: 15,
      deger: true,
      columns: [
        {
          label: 'ReqId',
          field: 'staffRequestId',
          hidden: true,
        },
        {
          label: 'Logo',
          field: 'firmInfo.firmLogo', // 'Logo', // this.fieldfn,
          html: true,
          sortable: false,
        },
        {
          label: 'Firma Adı',
          field: 'firmInfo.firmTitle',
        },
        {
          label: 'Açılış Tarihi',
          field: 'requestOpenDate',
        },
        {
          label: 'Pozisyon',
          field: 'position',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
      loading: false,
      dugme: 0,
    }
  },
  computed: {
    filteredFirm() {
      return this.rows.filter(p => p.firmInfo.firmTitle.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
      || p.factorPosition.staffPositionName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1)
    },
  },
  created() {
    this.getSummary()
  },
  mounted() {

  },
  methods: {
    fieldfn(rowObj) {
      return `<img width="80" heigt="80" src="${rowObj.firmInfo.firmLogo}" />`
    },
    getSummary() {
      apiTalep.GetSummary(0).then(res => {
        this.rows = res.data.list
        this.deger = false
      })
    },
    getImg(row) {
      return `${row.firmInfo.firmLogo}`
    },
    clearModal() {
    },
    onDataGridItemClicked(params) {
      return params
    },
    async onEvaluateBtnClicked(row) {
      this.loading = true
      this.dugme = row.staffRequestId
      await apiEval.EvaluateStaffRequest(row.staffRequestId).then(resp => {
        if (resp.data.count > 0) {
          apiTalep.GetStaffRequestById(row.staffRequestId).then(tlp => {
            const talep = tlp.data.entity
            talep.RequestStatus = 1
            apiTalep.UpdateStaffRequest(talep).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Talep eşleştirmesi tamamlandı',
                  text: 'Talep eşleştirmesi tamamlandı',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.go(0)
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Talep eşleştirmesi tamamlandı',
              text: 'Talep eşleştirmesi tamamlandı. Eşleşmeye uygun aday bulunamadı.',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.go(0)
        }
        this.loading = false
        // this.$router.go(0)
      })
    },
    onDuzenleBtnClicked(row) {
      this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestId, firmInfo: row.firmInfo } })
    },
    onGetFirmClicked(row) {
      // this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestId, firmObj: row.firmInfo } })
      this.$router.push({ name: 'firma-kart', params: { firmId: row.firmInfo.firmInfoId } })
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
